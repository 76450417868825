import requestsModal from '/src/data/modals/requests-modal'
import schemas from '/functions/shared/schemas'
import consoleLog from '/src/utils/console-log'
import makeRequest from '/src/data/index'

const requestsRepo = {
  sendRequestToManager: async (data) => {
    try {
      const result = await makeRequest({
        data,
        endpoint: 'requestToManager'
      })

      // validating the data
      const validatedResult = schemas.requestToManager.validateSync(result)

      // parse data here

      return validatedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  handleEmployeeRequest: async (data) => {
    try {
      const result = await makeRequest({
        data,
        endpoint: 'handleEmployeeRequest'
      })

      consoleLog(
        'result in handleEmployeeRequest before parsing and validation',
        result
      )

      // validating the data
      const validatedResult = schemas.handleEmployeeRequest.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  getEmployeeRequests: async (data) => {
    try {
      const result = await makeRequest({
        data,
        endpoint: 'getEmployeeRequests'
      })

      consoleLog(
        'result in getEmployeeRequests before parsing and validation',
        result
      )

      let parsedResult = []
      if (result.length) {
        // validating the data
        const validatedResult = schemas.getEmployeeRequests.validateSync(result)

        // parse data here
        parsedResult = requestsModal.prepareData(validatedResult)
      }

      consoleLog(
        'parsedResult in getEmployeeRequests after parsing and checking',
        parsedResult
      )
      return parsedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  }
}

export default requestsRepo
