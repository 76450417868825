interface Button {
  label: (flag: boolean) => string
  isSecondary: boolean
  upgradeRequest?: boolean
}

export interface Pricing {
  id: number
  title: string
  range: string
  button: Button
  billing?: boolean
  desc?: string
  features: string[]
}

export const pricing: Pricing[] = [
  {
    id: 1,
    title: 'Starter',
    range: '10 - 100',
    desc: '* Fixed pricing up to 50 users.',
    billing: true,
    button: {
      label: (flag: boolean) => (flag ? 'Start Now' : 'Coming Soon!'),
      isSecondary: false
    },
    features: [
      'Automate performance assessment & calibration',
      'Double the accuracy with incentive-compatible peer reviews',
      'Quantify talent and enhance performance',
      'Effortlessly calibrate performance at scale'
    ]
  },
  {
    id: 2,
    title: 'Enterprise',
    range: '101 + ',
    button: {
      label: (flag: boolean) =>
        !flag ? 'Request Upgrade' : 'We received your request!',
      isSecondary: true,
      upgradeRequest: true
    },
    desc: 'Contact us for pricing or to request a demo',
    features: [
      'Automate performance assessment & calibration',
      'Double the accuracy with incentive-compatible peer reviews',
      'Quantify talent and enhance performance',
      'Effortlessly calibrate performance at scale'
    ]
  }
]

export const MIN_EMPLOYEES = 50
export const CURRENCY_SYMBOL = '$'
export const MONTHLY_PRICE = 8.49
export const BIANNUAL_PRICE = 6.99
export const BI_ANNUALLY_DISCOUNT = Math.round(
  (1 - BIANNUAL_PRICE / MONTHLY_PRICE) * 100
)
