import * as Sentry from '@sentry/react'
import { captureConsoleIntegration } from '@sentry/integrations'

// Messages to ignore (warnings or errors)
// const ignoredMessages = [
//   'INTERNAL ASSERTION FAILED: Pending promise was never set',
//   'Not valid image extension'
// ]

// initialize sentry as early as possible
Sentry.init({
  dsn: 'https://71e1165a6025454cb571f54976ae6dee@o4504433927979008.ingest.sentry.io/4504434284888064',
  // // Below two are the configurations for session replay
  // // This sets the sample rate to be 10%. You may want this to be 100% while
  // // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,
  // // If the entire session is not sampled, use the below sample rate to sample
  // // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing(),
    captureConsoleIntegration({
      levels: ['error', 'warn']
    }),
    new Sentry.Integrations.TryCatch({ setTimeout: true }) // Capture more context
    // TODO: Make sentry session replay prod only available later
    // new Sentry.Replay({
    //   maskAllText: true,
    //   blockAllMedia: true
    // })
  ],
  environment: import.meta.env.VITE_FIREBASE_PROJECT_ID,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  initialScope: {
    user: {
      id: 'User not logged in yet',
      username: 'User not logged in yet',
      email: 'User not logged in yet',
      roles: 'User not logged in yet'
    }
  }
  // beforeSend(event, hint) {
  //   // Ignore based on message content
  //   if (
  //     ignoredMessages.some((msg) => event.message?.includes(msg)) ||
  //     event.exception?.values?.some((exception) =>
  //       ignoredMessages.some((msg) => exception?.value?.includes(msg))
  //     )
  //   ) {
  //     return null // Drop the event
  //   }

  //   return event // Send the event if no conditions matched
  // }
})
