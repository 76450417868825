import { resultsByQuestionSchema } from './common/results-by-question.js'
import { dateSchema, evaluationConfigSchema } from './common/evaluation.js'
import { userDataForResultsSchema } from './common/user-object.js'
import { array, number, object, string } from 'yup'

export const getResultsForUser = object()
  .shape({
    results: array()
      .of(
        object()
          .shape({
            evaluationId: string().required('Evaluation ID field is required.'),
            resultsByQuestion: array()
              .of(resultsByQuestionSchema)
              .required('Results by question field is required.'),
            uid: string().required('UID field is required.'),
            userId: string().required('User ID field is required.'),
            evaluationName: string().required(
              'Evaluation name field is required.'
            ),
            notes: object()
              .shape({
                selfNote: string().defined('Self note field is required.'),
                managerNote: string().defined('Manager note field is required.')
              })
              .required('Notes field is required.'),
            evaluationConfig: evaluationConfigSchema,
            evaluationQuestions: array().required(
              'Evaluation questions field is required.'
            )
          })
          .noUnknown()
      )
      .ensure()
  })
  .noUnknown()

export const getWorkforceResults = array().of(
  object().shape({
    userResults: array()
      .of(
        object().shape({
          evaluationId: string().required('Evaluation ID field is required.'),
          selectedPeople: array()
            .of(string())
            .required('Selected people field is required.'),
          reviewerPeople: array()
            .of(string())
            .required('Reviewer people field is required.'),
          resultsByQuestion: array()
            .of(resultsByQuestionSchema)
            .required('Results by question field is required.'),
          uid: string().required('UID field is required.'),
          notes: object().shape({
            selfNote: string().defined('Self note field is required.'),
            managerNote: string().defined('Manager note field is required.')
          }),
          user: object()
            .shape({
              team: string().optional(),
              jobLevel: number().optional(),
              division: string().optional(),
              seniority: string().optional(),
              cohort: string().optional(),
              jobTitle: string().optional(),
              manager: string().optional()
            })
            .required('User field is required.'),
          evaluationName: string().required(
            'Evaluation name field is required.'
          ),
          endDate: dateSchema.required('End date field is required.'),
          evaluationConfig: evaluationConfigSchema.required(
            'Evaluation config field is required.'
          )
        })
      )
      .required('User results field is required.'),
    ...userDataForResultsSchema.fields
  })
)

export const updateResultNotes = object()
  .shape({
    notes: object().shape({
      selfNote: string().defined('Self note field is required.'),
      managerNote: string().defined('Manager note field is required.')
    }),
    resultId: string().required('Result ID field is required.')
  })
  .noUnknown()

export const generateResultSuggestion = string().required(
  'Result summary field is required.'
)
