import { Suspense, lazy } from 'react'
import { AuthGuard } from '/src/guards/auth-guard'
import { DemoGuard } from '/src/guards/demo-guard'
import { AnimatedLogo } from '/src/components/animated-logo'
import { paths } from '/src/constants/paths'
import LoadingOverlay from '/src/components/loading-overlay'

// Firebase
const FirebaseSignUpForm = lazy(() => import('/src/pages/sign-up/sign-up-form'))

export const signUpRoutes = [
  {
    path: paths.signUpForm,
    element: (
      <AuthGuard>
        <DemoGuard>
          <Suspense
            fallback={
              <LoadingOverlay mx={10} my={4} height={50}>
                <AnimatedLogo width={60} />
              </LoadingOverlay>
            }
          >
            <FirebaseSignUpForm />
          </Suspense>
        </DemoGuard>
      </AuthGuard>
    )
  }
]
