import PropTypes from 'prop-types'
import Menu01Icon from '@untitled-ui/icons-react/build/cjs/Menu01'
import { Box, IconButton, Stack, SvgIcon, useMediaQuery } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { AccountButton } from '/src/layouts/dashboard/account-button'
import { FAQButton } from '/src/components/faq/faq-button'
import { useSettings } from '/src/hooks/use-settings'
import { SIDE_NAV_WIDTH } from '.'
import { useUser } from '/src/hooks/use-user'
import UpgradeToFullVersionButton from '/src/components/upgrade-to-full-version'
import { USER_STATUS } from '/functions/shared/constants/account-statuses'

const TOP_NAV_HEIGHT = 64

export const TopNav = (props) => {
  const { onMobileNavOpen, isSelfServiceOn, ...other } = props
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const settings = useSettings()
  const { user } = useUser()

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`
        },
        top: 0,
        width: '100%',
        zIndex: (theme) => theme.zIndex.appBar
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          pl: 1
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          sx={{
            justifyContent: 'flex-end',
            flexGrow: 1,
            mr: 2
          }}
          spacing={2}
        >
          {user.status === USER_STATUS.DEMO ? (
            <UpgradeToFullVersionButton
              user={user}
              isSelfServiceOn={isSelfServiceOn}
            />
          ) : null}
          <FAQButton settings={settings} />
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  )
}

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
}
