// Firebase imports
import { initializeApp } from 'firebase/app' //Firebase App
import { firebaseConfig } from './config'
import { getAuth, OAuthProvider } from 'firebase/auth' //Firebase Auth
import { getFunctions } from 'firebase/functions'
// import { connectFunctionsEmulator } from 'firebase/functions'
// import { connectAuthEmulator } from 'firebase/auth'

// Initializing the firebase app
export const app = initializeApp(firebaseConfig)

// Getting firebase auth
export const auth = getAuth(app)

// Getting firebase functions
export const functions = getFunctions(app, 'europe-west3')

// Getting the project id
const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID

let baseUrl = `https://europe-west3-${projectId}.cloudfunctions.net`

// Connects to emulator  and you can test functions locally
// Run "firebase emulators:start" before to deploy functions
// if (import.meta.env.VITE_NODE_ENV === 'dev') {
//   baseUrl = `http://localhost:5001/${projectId}/europe-west3`
//   connectFunctionsEmulator(functions, 'localhost', 5001)
//   connectAuthEmulator(auth, 'http://127.0.0.1:9099')
// }

export { baseUrl }

// Microsoft SSP
export const microsoftAuthProvider = new OAuthProvider('microsoft.com')
microsoftAuthProvider.setCustomParameters({
  // Force account selection page
  prompt: 'select_account'
})
