import statsModal from '/src/data/modals/stats-modal'
import schemas from '/functions/shared/schemas'
import consoleLog from '/src/utils/console-log'
import makeRequest from '/src/data/index'

const statsRepo = {
  getReviewStats: async (data) => {
    try {
      const result = await makeRequest({ data, endpoint: 'getReviewStats' })

      consoleLog(
        'result in getReviewStats before validation and parsing',
        result
      )

      // validate the result
      const validatedResult = schemas.getReviewStats.validateSync(result)

      // parse the result
      const parsedResult = statsModal.prepareForCharts(validatedResult)

      consoleLog(
        'result in getReviewStats after parsing and validation',
        parsedResult
      )

      return parsedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  }
}

export default statsRepo
