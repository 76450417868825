import { useMutation } from 'react-query'
import { useUser } from '/src/hooks/use-user'
import demoRepo from '/src/data/repos/demo-repo'

// Hook is used to perform operations on the mutation of employees
export const useUpgradeRequest = () => {
  const { updateUser } = useUser()
  return useMutation({
    mutationFn: async (data) => await demoRepo.upgradeRequest(data),
    // Do something with the data after the mutation has been completed
    onSuccess: (newUserObj) => {
      updateUser(newUserObj)
    },
    onError: (error) => {
      console.warn('useUpgradeRequest error: ', error)
    }
  })
}
