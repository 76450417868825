import schemas from '/functions/shared/schemas'
import makeRequest from '/src/data/index'

const organizationRepo = {
  updateOrganization: async (data) => {
    const result = await makeRequest({ data, endpoint: 'updateOrganization' })

    // validate the result
    const validatedResult = schemas.updateOrganization.validateSync(result)

    return validatedResult
  },
  getOrganization: async () => {
    try {
      const result = await makeRequest({ endpoint: 'getOrganization' })

      // validate the result
      const validatedResult = schemas.getOrganization.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error @organizationRepo -> getOrganization', error)
      throw error
    }
  }
}

export default organizationRepo
