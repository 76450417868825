import { boolean, object } from 'yup'

export const getAppConfig = object()
  .shape({
    isMaintenance: boolean()
      .nullable()
      .defined('Is Maintenance field is required'),
    isSelfServiceOn: boolean()
      .nullable()
      .defined('Is Self Service On field is required')
  })
  .noUnknown()
