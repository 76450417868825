class UserResultsModal {
  #roundScores = (results) => {
    return results.map((result) => ({
      ...result,
      resultsByQuestion: result.resultsByQuestion.map((question) => ({
        ...question,
        behaviorLink:
          result.evaluationQuestions.find(
            (evQuestion) => evQuestion.questionId === question.questionId
          )?.helperLink ?? null,
        scores: question.scores.map((score) => ({
          ...score,
          // Check if value is a number and not less than zero
          value:
            typeof score.value === 'number' && score.value >= 0
              ? Math.round(score.value)
              : score.value
        }))
      }))
    }))
  }

  prepareData(data) {
    const { results } = data

    return {
      results: this.#roundScores(results)
    }
  }
}

const userResultsModal = new UserResultsModal()

export default userResultsModal
