import { FirestoreTimestamp } from '/types/common'

export const formatUnixDate = (unixDate: FirestoreTimestamp) => {
  if (unixDate) {
    return new Date(
      unixDate?._seconds * 1000 + Math.floor(unixDate?._nanoseconds / 1000000)
    )
  }

  return unixDate
}
