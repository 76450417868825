import schemas from '/functions/shared/schemas'
import makeRequest from '/src/data/index'

const paymentsRepo = {
  getStripeCustomer: async () => {
    try {
      const result = await makeRequest({
        endpoint: 'getStripeCustomer'
      })

      // validate the result
      const validatedResult = schemas.getStripeCustomer.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error @paymentsRepo -> getStripeCustomer', error)
      throw error
    }
  },
  increaseSubscriptionSize: async (data) => {
    try {
      const result = await makeRequest({
        data,
        endpoint: 'increaseSubscriptionSize'
      })

      // validate the result
      const validatedResult =
        schemas.increaseSubscriptionSize.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error @paymentsRepo -> increaseSubscriptionSize', error)
      throw error
    }
  },
  createCheckoutSession: async (data) => {
    try {
      const result = await makeRequest({
        data,
        endpoint: 'createCheckoutSession'
      })

      // validate the result
      const validatedResult = schemas.createCheckoutSession.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error @paymentsRepo -> createCheckoutSession', error)
      throw error
    }
  },
  createCustomerPortalSession: async () => {
    try {
      const result = await makeRequest({
        endpoint: 'createCustomerPortalSession'
      })

      // validate the result
      const validatedResult =
        schemas.createCustomerPortalSession.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error @paymentsRepo -> createCustomerPortalSession', error)
      throw error
    }
  }
}

export default paymentsRepo
