export const paths = {
  index: '/',
  login: '/login',
  checkout: {
    success: '/checkout-success',
    cancelDemo: '/checkout-cancel-demo',
    cancelPurchase: '/checkout-cancel-purchase'
  },
  signUp: '/sign-up',
  signUpForm: '/sign-up-form',
  dashboard: {
    index: '/',
    reviews: {
      index: '/reviews',
      selection: (evaluationId) => `/reviews/${evaluationId}`,
      questions: (evaluationId) => `/reviews/${evaluationId}/questions`,
      submit: (evaluationId) => `/reviews/${evaluationId}/submit`,
      results: (evaluationId) => `/reviews/${evaluationId}/results`
    },
    goals: '/goals',
    admin: {
      index: '/admin',
      reviews: {
        index: '/admin/reviews',
        create: '/admin/create',
        details: (evaluationId) => `/admin/${evaluationId}/details`,
        update: (evaluationId) => `/admin/${evaluationId}/update`
      },
      management: '/admin/management'
    },
    manager: {
      index: '/manager',
      reviews: {
        index: '/manager/reviews',
        progress: (evaluationId) => `/manager/reviews/${evaluationId}/progress`
      },
      goals: '/manager/goals'
    }
  },
  docs: '#',
  401: '/401',
  404: '/404',
  500: '/500'
}

export const landingPageURL = 'https://incompass-labs.com'
