const isDevOrStage =
  import.meta.env.VITE_NODE_ENV === 'dev' ||
  import.meta.env.VITE_NODE_ENV === 'stage'

const consoleLog = (message: any, ...args: any[]): null => {
  if (isDevOrStage) {
    console.log(message, ...args)
  }

  return null
}

export default consoleLog
