class EmployeesModal {
  #parseDisabledEmployees = (employees, disabledEmployees) => {
    // adding isDisabled=true to the appropriate employees
    return disabledEmployees.forEach((disabledEmployee) => {
      employees.find(
        (employee) => employee.uid === disabledEmployee.uid
      ).isDisabled = true
    })
  }

  #addIsDisabledToEmployees = (employees) => {
    // adding isDisabled false to the employees
    return employees.map((employee) => ({
      ...employee,
      isDisabled: false
    }))
  }

  #parseEmployees = (employees) => {
    // remove users that have no id or name
    const removeUserHasNoIdOrName = employees.filter((employee) => {
      return employee.uid !== null && employee.fullName !== null
    })

    // asc sorting by fullName
    removeUserHasNoIdOrName.sort((a, b) => {
      let fa = a?.fullName?.toLowerCase(),
        fb = b?.fullName?.toLowerCase()

      if (fa < fb) {
        return -1
      }
      if (fa > fb) {
        return 1
      }
      return 0
    })

    return removeUserHasNoIdOrName
  }

  prepareResult = (result) => {
    // destructure result
    const { employees, selectedUsers, employeeRequest, manager } = result

    // adding isDisabled false to every employee
    const employeesIsDisabledAdded = this.#addIsDisabledToEmployees(employees)

    // parse employees
    const parsedEmployees = this.#parseEmployees(employeesIsDisabledAdded)

    this.#parseDisabledEmployees(parsedEmployees, selectedUsers)

    return {
      employees: parsedEmployees,
      selectedEmployees: selectedUsers,
      employeeRequest,
      manager
    }
  }
}

const employeesModal = new EmployeesModal()

export default employeesModal
