import { useMutation } from 'react-query'
import paymentsRepo from '/src/data/repos/payment-repo'
import { useStripe } from '@stripe/react-stripe-js'

// Hook is used to perform operations on the mutation of employees
export const useNavigateCheckout = ({ from, user }) => {
  const stripe = useStripe()
  return useMutation({
    mutationFn: async (data) => {
      const response = await paymentsRepo.createCheckoutSession({
        ...data,
        ...user,
        from
      })

      // Redirect to the checkout page
      const result = await stripe.redirectToCheckout({
        sessionId: response.sessionId
      })

      // If there is an error, show the error message
      if (result.error) {
        throw new Error(result.error)
      }
    },
    onError: (error) => {
      console.warn(`useNavigateCheckout error: ${from}`, error)
    }
  })
}
