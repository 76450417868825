import { array, object, string } from 'yup'
import {
  dateSchema,
  evaluationSchema,
  questionsSchema
} from './common/evaluation.js'
import { ROLES } from '../constants/roles.js'
import { reviewSchema } from './common/review.js'
import { userSchema } from './common/user-object.js'

export const batchGetEvaluations = array().of(evaluationSchema).ensure()

const getEvaluationSchemaForAdmin = evaluationSchema

const getEvaluationSchemaForManager = object().shape({
  company: string().required('Company field is required.'),
  description: string().optional(),
  name: string().required('Name field is required.'),
  questions: questionsSchema.required('Questions field is required.'),
  status: string().required('Status field is required.'),
  uid: string().required('UID field is required.'),
  startDate: dateSchema.required('Start date field is required.'),
  endDate: dateSchema.nullable().defined('End date field is required.'),
  expectedEndDate: dateSchema.required('Expected end date field is required.')
})

// Conditional YUP check validation
export const getEvaluation = (userRoles) => {
  return userRoles.includes(ROLES.ADMIN)
    ? getEvaluationSchemaForAdmin
    : getEvaluationSchemaForManager
}

export const createEvaluation = object()
  .shape({
    evaluation: evaluationSchema.required('Evaluation field is required.'),
    users: array().of(userSchema).required('Users field is required.')
  })
  .noUnknown(true)

export const updateEvaluation = object()
  .shape({
    evaluation: evaluationSchema.required('Evaluation field is required.'),
    users: array().of(userSchema).required('Users field is required.')
  })
  .noUnknown(true)

export const deleteEvaluation = evaluationSchema

export const startEvaluation = array()
  .of(reviewSchema)
  .required('Reviews are required.')

export const completeEvaluation = evaluationSchema

export const updateEvaluationConfig = evaluationSchema
