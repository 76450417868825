const REDIRECTED_TO_CHECKOUT_FROM = {
  DEMO: 'DEMO',
  PURCHASE: 'PURCHASE'
}

const BILLING_PERIOD = {
  BI_ANNUALLY: 'bi-annually',
  MONTHLY: 'monthly'
}

const MAX_ALLOWED_SIZE = 100
const MIN_ALLOWED_SIZE = 50

const SUBSCRIPTION_VALIDATIONS = {
  SUBSCRIPTION_NOT_ACTIVE: 'subscriptionNotActive',
  SUBSCRIPTION_NOT_ENOUGH_SEATS: 'subscriptionNotEnoughSeats'
}

const ERROR_MESSAGES = {
  DONT_ALLOW_SUBSCRIPTION_SEAT_LIMIT_EXCEEDING:
    'Your subscription plan does not provide enough seats for the company.',
  DONT_ALLOW_INACTIVE_SUBSCRIPTION: 'Your subscription is not active.',
  DONT_ALLOW_MAX_SIZE_EXCEEDING: `subscription size can not increase the above ${MAX_ALLOWED_SIZE}.`
}

export {
  REDIRECTED_TO_CHECKOUT_FROM,
  BILLING_PERIOD,
  SUBSCRIPTION_VALIDATIONS,
  ERROR_MESSAGES,
  MAX_ALLOWED_SIZE,
  MIN_ALLOWED_SIZE
}
