// Constants
import { ROLES } from '/functions/shared/constants/roles'
import { USER_STATUS } from '/functions/shared/constants/account-statuses'
import { PERMISSION_FIELDS } from '/functions/shared/constants/permissions-fields'
import { featuresMap } from '/functions/shared/constants/features'

const { LOCATION, DIVISION, TEAM, SENIORITY } = PERMISSION_FIELDS

// Access controls should either meet the first or the second condition
// Checks with AND operation inside each condition and OR operation between conditions

// Access Control Settings for each functionality mapped with feature names

export const ACCESS_CONTROLS = {
  // Pages
  ADMIN_PAGES: [
    {
      roles: [ROLES.ADMIN]
    }
  ],
  MANAGER_PAGES: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  EMPLOYEE_PAGES: [
    {
      roles: [ROLES.EMPLOYEE]
    }
  ],
  ADMIN_OR_SPECIAL_PERMISSION_PAGES: [
    {
      roles: [ROLES.ADMIN]
    },
    {
      permissions: [LOCATION, DIVISION, TEAM, SENIORITY]
    }
  ],
  // Drawer Tabs
  MY_REVIEWS_TAB: [
    {
      roles: [ROLES.EMPLOYEE]
    }
  ],
  MY_GOALS_TAB: [
    {
      roles: [ROLES.EMPLOYEE],
      feature: featuresMap.GOALS
    }
  ],
  MANAGER_ANALYTICS_TAB: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  MANAGER_REVIEW_CYCLES_TAB: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  MANAGER_TEAM_GOALS_TAB: [
    {
      roles: [ROLES.MANAGER],
      feature: featuresMap.GOALS
    }
  ],
  ADMIN_ANALYTICS_TAB: [
    {
      roles: [ROLES.ADMIN]
    },
    {
      permissions: [LOCATION, DIVISION, TEAM, SENIORITY]
    }
  ],
  ADMIN_REVIEW_CYCLES_TAB: [
    {
      roles: [ROLES.ADMIN]
    }
  ],
  ADMIN_ORGANIZATION_TAB: [
    {
      roles: [ROLES.ADMIN]
    }
  ],
  // Analytics Tabs
  ADMIN_EXECUTIVE_OVERVIEW_TAB: [
    {
      roles: [ROLES.ADMIN]
    }
  ],
  ADMIN_DASHBOARD_ANALYSIS_TAB: [
    {
      roles: [ROLES.ADMIN]
    },
    {
      permissions: [LOCATION, DIVISION, TEAM, SENIORITY]
    }
  ],
  ADMIN_DASHBOARD_INSIGHTS_TAB: [
    {
      roles: [ROLES.ADMIN]
    },
    {
      permissions: [LOCATION, DIVISION, TEAM, SENIORITY]
    }
  ],
  ADMIN_DASHBOARD_CALIBRATION_TAB: [
    {
      roles: [ROLES.ADMIN],
      feature: featuresMap.REWARD_ALLOCATION
    }
  ],
  ADMIN_DASHBOARD_EMPLOYEE_GOALS_TAB: [
    {
      roles: [ROLES.ADMIN],
      feature: featuresMap.GOALS
    }
  ],
  MANAGER_DASHBOARD_ANALYSIS_TAB: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  MANAGER_DASHBOARD_INSIGHTS_TAB: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  // Demo Restricted Features
  REVIEWS_TABLE_BUTTONS: [
    {
      roles: [ROLES.ADMIN],
      statuses: [USER_STATUS.ACTIVE] // Not Demo
    }
  ],
  SINGULAR_DATA_INGESTION: [
    {
      roles: [ROLES.ADMIN],
      statuses: [USER_STATUS.ACTIVE], // Not Demo
      feature: featuresMap.SINGULAR_DATA_INGESTION
    }
  ],
  BULK_DATA_INGESTION: [
    {
      roles: [ROLES.ADMIN],
      statuses: [USER_STATUS.ACTIVE], // Not Demo
      feature: featuresMap.BULK_DATA_INGESTION
    }
  ],
  LEADERSHIP_BEHAVIORS: [
    {
      roles: [ROLES.ADMIN],
      feature: featuresMap.LEADERSHIP_BEHAVIORS
    }
  ],
  EMPLOYEE_REQUESTS: [
    {
      statuses: [USER_STATUS.ACTIVE] // Not Demo
    }
  ],
  BEHAVIOR_EXTERNAL_LINK: [
    {
      statuses: [USER_STATUS.ACTIVE] // Not Demo
    }
  ],
  LIMIT_FEEDBACK_RECEIVERS: [
    {
      roles: [ROLES.ADMIN],
      feature: featuresMap.LIMIT_FEEDBACK_RECEIVERS
    }
  ],
  DEVELOPMENT_PLAN: [
    {
      feature: featuresMap.DEVELOPMENT_PLAN
    }
  ]
}
