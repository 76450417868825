import { useMemo } from 'react'
import { SvgIcon } from '@mui/material'
import HomeSmile from '@untitled-ui/icons-react/build/cjs/HomeSmile'
import List from '@untitled-ui/icons-react/build/cjs/List'
import SportsScoreIcon from '@mui/icons-material/SportsScore'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import { DomainAddOutlined } from '@mui/icons-material'
import { paths } from '/src/constants/paths'
import { checkAccessControl } from '/src/hooks/use-access-control'
import { ACCESS_CONTROLS } from '/src/constants/access-control-settings'
import { useUser } from '/src/hooks/use-user'

export const useSections = () => {
  const { user } = useUser()

  const sections = useMemo(() => {
    return [
      {
        subheader: 'Home',
        items: [
          {
            title: 'My Reviews',
            permission: ACCESS_CONTROLS.MY_REVIEWS_TAB,
            path: paths.dashboard.reviews.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmile />
              </SvgIcon>
            )
          },
          {
            title: 'My Goals',
            permission: ACCESS_CONTROLS.MY_GOALS_TAB,
            path: paths.dashboard.goals,
            icon: (
              <SvgIcon fontSize="small">
                <SportsScoreIcon />
              </SvgIcon>
            )
          }
        ]
      },
      {
        subheader: 'Admin',
        items: [
          {
            title: 'Analytics',
            permission: ACCESS_CONTROLS.ADMIN_ANALYTICS_TAB,
            path: paths.dashboard.admin.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmile />
              </SvgIcon>
            )
          },
          {
            title: 'Review Cycles',
            permission: ACCESS_CONTROLS.ADMIN_REVIEW_CYCLES_TAB,
            path: paths.dashboard.admin.reviews.index,
            icon: (
              <SvgIcon fontSize="small">
                <List />
              </SvgIcon>
            )
          },
          {
            title: 'Organization',
            permission: ACCESS_CONTROLS.ADMIN_ORGANIZATION_TAB,
            path: paths.dashboard.admin.management,
            icon: (
              <SvgIcon fontSize="small">
                <DomainAddOutlined />
              </SvgIcon>
            )
          }
        ]
      },
      {
        subheader: 'My Team',
        items: [
          {
            title: 'Analytics',
            permission: ACCESS_CONTROLS.MANAGER_ANALYTICS_TAB,
            path: paths.dashboard.manager.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmile />
              </SvgIcon>
            )
          },
          {
            title: 'Review Cycles',
            permission: ACCESS_CONTROLS.MANAGER_REVIEW_CYCLES_TAB,
            path: paths.dashboard.manager.reviews.index,
            icon: (
              <SvgIcon fontSize="small">
                <GroupsOutlinedIcon />
              </SvgIcon>
            )
          },
          {
            title: 'Goals',
            permission: ACCESS_CONTROLS.MANAGER_TEAM_GOALS_TAB,
            path: paths.dashboard.manager.goals,
            icon: (
              <SvgIcon fontSize="small">
                <SportsScoreIcon />
              </SvgIcon>
            )
          }
        ]
      }
    ]
  }, [])

  const filteredSections = useMemo(() => {
    return sections
      .map((section) => {
        const newItems = section.items.filter((item) =>
          checkAccessControl({ user, accessControl: item.permission })
        )
        return { ...section, items: newItems }
      })
      .filter((section) => section.items.length)
  }, [sections, user])

  // move employee to the top of the list then admin then manager
  const sortedSections = useMemo(() => {
    const employee = filteredSections.find(
      (section) => section.subheader === 'Home'
    )
    const admin = filteredSections.find(
      (section) => section.subheader === 'Admin'
    )
    const manager = filteredSections.find(
      (section) => section.subheader === 'My Team'
    )

    return [employee, manager, admin]
  }, [filteredSections])

  return useMemo(() => {
    return [...sortedSections.filter((section) => section !== undefined)]
  }, [sortedSections])
}
