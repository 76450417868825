import { Modal, Stack, Typography } from '@mui/material'
import { Logo } from './logo'

export const MobileWarning = () => {
  return (
    <Modal open={true}>
      <Stack
        sx={{
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'background.default',
          p: 4
        }}
        spacing={8}
      >
        <Logo width={100} />
        <Stack spacing={1}>
          <Typography variant="h4" component="h1" gutterBottom>
            For the best experience, please use a larger screen.
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  )
}
