import { paths } from '/src/constants/paths'
import { useAccessControl } from '/src/hooks/use-access-control'
import { Navigate } from 'react-router-dom'

const AccessControlGuard = (props) => {
  const { accessControl, children, isPage = false } = props

  // Check if the user has access to the component
  const access = useAccessControl(accessControl)

  if (access) {
    return <>{children}</>
  } else if (isPage) {
    // Handle pages separately, redirect to main page
    return <Navigate to={paths.dashboard.index} />
  } else {
    // Optionally render a fallback UI or nothing
    return null
  }
}

export default AccessControlGuard
