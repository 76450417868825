import { lazy, Suspense } from 'react'
import { paths } from '../constants/paths'
import { REDIRECTED_TO_CHECKOUT_FROM } from '/functions/shared/constants/payment'
import { AnimatedLogo } from '../components/animated-logo'
import LoadingOverlay from '../components/loading-overlay'
import { AuthGuard } from '../guards/auth-guard'
const CheckoutSuccess = lazy(() =>
  import('/src/pages/checkout/checkout-success.jsx')
)
const CheckoutCancel = lazy(() =>
  import('/src/pages/checkout/checkout-cancel.jsx')
)

const CheckoutWrapper = ({ children }) => (
  <AuthGuard>
    <Suspense
      fallback={
        <LoadingOverlay mx={10} my={4} height={50}>
          <AnimatedLogo width={60} />
        </LoadingOverlay>
      }
    >
      {children}
    </Suspense>
  </AuthGuard>
)

export const checkoutRoutes = [
  {
    path: paths.checkout.success,
    element: (
      <CheckoutWrapper>
        <CheckoutSuccess />
      </CheckoutWrapper>
    )
  },
  {
    path: paths.checkout.cancelDemo,
    element: (
      <CheckoutWrapper>
        <CheckoutCancel from={REDIRECTED_TO_CHECKOUT_FROM.DEMO} />
      </CheckoutWrapper>
    )
  },
  {
    path: paths.checkout.cancelPurchase,
    element: (
      <CheckoutWrapper>
        <CheckoutCancel from={REDIRECTED_TO_CHECKOUT_FROM.PURCHASE} />
      </CheckoutWrapper>
    )
  }
]
