import { array, object, string, boolean } from 'yup'
import { evaluationSchema } from './common/evaluation.js'
import { userSchema } from './common/user-object.js'
import { featuresMap } from '../constants/features.js'

export const internalAdminSchema = object()
  .shape({
    userId: string().strict().required('User ID is required.'),
    authProvider: string().optional(),
    canLinkUsers: boolean().optional()
  })
  .noUnknown()

const companyCustomizationConfigStatSchema = object()
  .shape({
    name: string().required('Name field is required.'),
    colorCode: string().required('Color code field is required.'),
    font: string().required('Font field is required.'),
    logo: string().nullable().defined('Logo field is required.')
  })
  .noUnknown()

const organizationStatSchema = object()
  .shape({
    authProvider: string().optional(),
    customizationConfig: companyCustomizationConfigStatSchema.required(
      'customizationConfig is required.'
    ),
    domains: array().of(string()).optional(),
    features: object()
      .shape(
        Object.keys(featuresMap).reduce((acc, key) => {
          acc[featuresMap[key]] = boolean().optional()
          return acc
        }, {})
      )
      .notRequired(),
    evaluations: array().of(evaluationSchema).optional(),
    status: string().required('status is required.'),
    uid: string().required('uid is required.'),
    users: array().of(userSchema).required('users are required.')
  })
  .noUnknown()

const reviewStatSchema = object()
  .shape({
    evaluation: string().strict().required('Evaluation id field is required.'),
    people: array().of(string()).required('People field is required.'),
    status: string().strict().required('Status field is required.')
  })
  .noUnknown()

export const overallStatistics = object()
  .shape({
    organizations: array().of(organizationStatSchema),
    admin: internalAdminSchema
  })
  .noUnknown()

export const evaluationReviews = object()
  .shape({
    reviews: array().of(reviewStatSchema)
  })
  .noUnknown()
