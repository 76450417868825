import { alpha, Box, Stack, styled, Switch, Typography } from '@mui/material'
import React from 'react'
import RedditTextField from '/src/components/reddit-text-field'
import { CURRENCY_SYMBOL, MIN_EMPLOYEES } from './constants'
import { FormikProps } from 'formik'

export const Grid = styled('div')(({ theme }) => ({
  'gap': '35px',
  'display': 'grid',
  'gridTemplateColumns': 'repeat(2, 460px)',
  'justifyContent': 'center',
  ' @media screen and (max-width: 1024px)': {
    gap: '20px',
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  ' @media screen and (max-width: 750px)': {
    gridTemplateColumns: ' 1fr',
    gap: '40px'
  },
  'marginBottom': '21px'
}))

export const PriceTable = styled('div')(({ theme }) => ({
  'backgroundColor': alpha(theme.palette.background.paper, 0.06),
  'color': theme.palette.text.primary,
  'boxShadow': theme.shadows[1],
  'padding': '30px',
  'border': `1px solid ${theme.palette.primary.main}`,
  'borderRadius': '10px',
  'textAlign': 'center',
  'height': '100%',
  'display': 'flex',
  'flexDirection': 'column',
  '@media (max-width: 1024px)': {
    padding: '35px'
  },
  '@media (max-width: 768px)': {
    padding: '25px 14px'
  },
  '@media (max-width: 670px)': {
    padding: '20px 30px'
  }
}))

export const AntSwitch = styled(Switch)(({ theme }) => ({
  'width': 28,
  'height': 16,
  'padding': 0,
  'display': 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    'padding': 2,
    '&.Mui-checked': {
      'transform': 'translateX(12px)',
      'color': '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main, // Use primary color here
        ...theme.applyStyles('dark', {
          backgroundColor: theme.palette.primary.dark // Use dark variant of primary color
        })
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)'
    })
  }
}))

export const NumberOfEmployees = ({
  numberOfEmployees,
  handleNumberOfEmployees,
  pricePerMonth,
  formik
}: {
  numberOfEmployees: number | ''
  handleNumberOfEmployees: (event: React.ChangeEvent<HTMLInputElement>) => void
  pricePerMonth: number
  formik?: FormikProps<any>
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <RedditTextField
        value={numberOfEmployees}
        onChange={handleNumberOfEmployees}
        label="Number of employees"
        size="small"
        type="number"
        variant="filled"
        InputLabelProps={{
          sx: {
            fontSize: 'inherit'
          },
          shrink: true,
          htmlFor: 'number-of-employees'
        }}
        InputProps={{
          sx: {
            fontSize: 'inherit'
          },
          id: 'number-of-employees'
        }}
        error={
          formik
            ? formik.touched.companySize && Boolean(formik.errors.companySize)
            : false
        }
        helperText={
          formik?.touched?.companySize && formik?.errors?.companySize
            ? String(formik.errors.companySize)
            : undefined
        }
        inputProps={{
          min: 0,
          max: 100
        }}
        sx={{
          fontSize: 14,
          minWidth: 160,
          maxWidth: 160,
          width: '100%'
        }}
      />
      <Typography
        sx={{
          fontSize: 14,
          marginLeft: 1
        }}
      >
        <Box component="span">
          × {CURRENCY_SYMBOL} {pricePerMonth} =
          <Box component="span" sx={{ color: 'red', fontWeight: 500 }}>
            {CURRENCY_SYMBOL +
              (
                Number(pricePerMonth) *
                (Number(numberOfEmployees) < MIN_EMPLOYEES
                  ? MIN_EMPLOYEES
                  : Number(numberOfEmployees))
              ).toFixed(2)}
          </Box>
          <Box
            component="span"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontSize: '12px'
            }}
          >
            /month
          </Box>
        </Box>
        <br />
        <Typography
          variant="caption"
          sx={{
            color: (theme) => theme.palette.text.secondary
          }}
        >
          Fixed price up to 50 employees
        </Typography>
      </Typography>
    </Stack>
  )
}
