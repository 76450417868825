import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import { useDialog } from '/src/hooks/use-dialog'
import { Box, IconButton, styled } from '@mui/material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

interface CustomDialogProps {
  children: React.ReactNode
  title: string
  controller: ReturnType<typeof useDialog>
  openButton: React.ReactNode
  actions?: React.ReactNode
  dividers?: boolean
  fullWidth?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose?: () => void
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  children,
  title,
  actions,
  controller,
  openButton,
  dividers = false,
  fullWidth = true,
  maxWidth = 'sm',
  onClose
}) => {
  const { handleClose, open } = controller

  return (
    <div>
      {openButton}
      <BootstrapDialog
        open={open}
        onClose={onClose ?? handleClose}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        aria-labelledby="customized-dialog-title"
      >
        <Box sx={{ padding: 2 }}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {title}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose ?? handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers={dividers}>{children}</DialogContent>
          {actions ? <DialogActions>{actions}</DialogActions> : null}
        </Box>
      </BootstrapDialog>
    </div>
  )
}

export default CustomDialog
