import { bool, object, string } from 'yup'

export const createCheckoutSession = object()
  .shape({
    sessionId: string().required()
  })
  .noUnknown()

export const createCustomerPortalSession = object()
  .shape({
    portalUrl: string().required()
  })
  .noUnknown()

export const increaseSubscriptionSize = object()
  .shape({
    requiresAction: bool().required(),
    clientSecret: string().optional()
  })
  .noUnknown()

export const getStripeCustomer = object().shape({
  customerId: string().nullable()
})
