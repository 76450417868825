export function getSearchParams({ search }) {
  const searchParams = new URLSearchParams(search)
  const params = {}
  for (const [key, value] of searchParams) {
    if (value === 'true' || value === 'false') {
      params[key] = value === 'true'
    } else {
      params[key] = value
    }
  }
  return params
}
