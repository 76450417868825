import { Stack, Typography } from '@mui/material'

const PricingHeader = () => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={1}
  >
    <Typography variant="h5">
      Flexible pricing for companies of all sizes
    </Typography>
    <Typography
      sx={{
        color: (theme) => theme.palette.text.secondary,
        fontWeight: 500
      }}
    >
      Choose a plan that works best for your company or team
    </Typography>
  </Stack>
)

export default PricingHeader
