import consoleLog from '/src/utils/console-log'
import schemas from '/functions/shared/schemas'
import makeRequest from '/src/data/index'

const demoRepo = {
  createDemoAccount: async (data) => {
    try {
      const result = await makeRequest({ data, endpoint: 'createDemoAccount' })

      consoleLog('createDemoAccount --> beforeParsing', result)

      // Validating the results
      const validatedResult = schemas.createDemoAccount.validateSync(result)

      // Parsing the results
      //   const parsedResult = userResultsModal.prepareData(validatedResult)

      consoleLog('createDemoAccount --> afterParsing', validatedResult)

      return validatedResult
    } catch (error) {
      console.warn('createDemoAccount error: ', error)
      throw new Error(error)
    }
  },
  upgradeRequest: async (data) => {
    try {
      const result = await makeRequest({ data, endpoint: 'upgradeRequest' })

      consoleLog('upgradeRequest --> beforeParsing', result)

      // Validating the results
      const validatedResult = schemas.upgradeRequest.validateSync(result)

      // Parsing the results
      //   const parsedResult = userResultsModal.prepareData(validatedResult)

      consoleLog('upgradeRequest --> afterParsing', validatedResult)

      return validatedResult
    } catch (error) {
      console.warn('upgradeRequest error: ', error)
      throw new Error(error)
    }
  }
}

export default demoRepo
