// Repo imports
import appConfigRepo from '/src/data/repos/app-config-repo'
import usersRepo from '/src/data/repos/users-repo'
import requestsRepo from '/src/data/repos/requests-repo'
import employeesRepo from '/src/data/repos/employees-repo'
import evaluationsRepo from '/src/data/repos/evaluations-repo'
import organizationRepo from '/src/data/repos/organizations-repo'
import resultsRepo from '/src/data/repos/results-repo'
import reviewsRepo from '/src/data/repos/reviews-repo'
import statsRepo from '/src/data/repos/stats-repo'
import paymentsRepo from '/src/data/repos/payment-repo'
// Other imports
import { useQuery } from 'react-query'
import consoleLog from '/src/utils/console-log'
import goalsRepo from '/src/data/repos/goals-repo'

// Considerations:
// 1. getOrganization is not used
// 2. repoData is only used in the getEvaluation (getUser hook can't be used in repo therefore
// userRoles are passed as repoData)

export const Endpoints = {
  GET_APP_CONFIG: 'getAppConfig',
  GET_COMPANY_PEOPLE: 'getCompanyPeople',
  GET_EMPLOYEE_REQUESTS: 'getEmployeeRequests',
  GET_EMPLOYEES: 'getEmployees',
  GET_EVALUATION: 'getEvaluation',
  GET_EVALUATIONS: 'getEvaluations',
  GET_ORGANIZATION: 'getOrganization',
  GET_RESULTS_FOR_USER: 'getResultsForUser',
  GET_REVIEW: 'getReview',
  GET_REVIEWS: 'getReviews',
  GET_REVIEW_STATS: 'getReviewStats',
  GET_WORKFORCE_RESULTS: 'getWorkforceResults',
  GET_STRIPE_CUSTOMER: 'getStripeCustomer',
  GET_USER_GOALS: 'getUserGoals',
  BATCH_GET_GOALS: 'batchGetGoals'
}

const repoEndpoints = {
  getAppConfig: () => appConfigRepo.getAppConfig(),
  getCompanyPeople: (data) => usersRepo.getCompanyPeople(data),
  getEmployeeRequests: (data) => requestsRepo.getEmployeeRequests(data),
  getEmployees: (data) => employeesRepo.getEmployees(data),
  getEvaluation: (data, repoData) =>
    evaluationsRepo.getEvaluation(data, repoData),
  getEvaluations: (data) => evaluationsRepo.getEvaluations(data),
  getOrganization: () => organizationRepo.getOrganization(),
  getResultsForUser: () => resultsRepo.getResultsForUser(),
  getReview: (data) => reviewsRepo.getReview(data),
  getReviews: () => reviewsRepo.getReviews(),
  getReviewStats: (data) => statsRepo.getReviewStats(data),
  getWorkforceResults: (data) => resultsRepo.getWorkforceResults(data),
  getStripeCustomer: () => paymentsRepo.getStripeCustomer(),
  getUserGoals: (data) => goalsRepo.getUserGoals(data),
  batchGetGoals: (data) => goalsRepo.batchGetGoals(data)
}

export default function useGetRequest({
  endpoint,
  id = null,
  data = {},
  config = {},
  repoData = null
}) {
  consoleLog('Request Info:\nEndpoint: ', endpoint + '\nData: ', data)
  const uniqueQueryKey = id ? `${endpoint}-${id}` : endpoint
  return useQuery(
    uniqueQueryKey,
    () => repoEndpoints[endpoint](data, repoData),
    config
  )
}
