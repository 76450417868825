import { createContext, useCallback, useEffect, useReducer } from 'react'
import { configureScope } from '@sentry/react'
import PropTypes from 'prop-types'
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  SAMLAuthProvider
} from 'firebase/auth'

import { auth } from '/src/config/firebase'
import usersRepo from '/src/data/repos/users-repo'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { toast } from 'react-toastify'
import { useRouter } from '/src/hooks/use-router'
import { paths } from '/src/constants/paths'
import { useLocation } from 'react-router'
import { localStorageService } from '/src/services/local-storage-service'

var ActionType
;(function (ActionType) {
  ActionType['AUTH_STATE_CHANGED'] = 'AUTH_STATE_CHANGED'
  ActionType['UPDATE_USER'] = 'UPDATE_USER'
})(ActionType || (ActionType = {}))

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  signingOut: false,
  user: null
}

const reducer = (state, action) => {
  const { isAuthenticated, user, signingOut } = action.payload
  if (action.type === 'AUTH_STATE_CHANGED') {
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  }
  if (action.type === 'UPDATE_USER') {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.payload.user
      }
    }
  }

  return { ...state, signingOut }
}

export const AuthContext = createContext({
  ...initialState,
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  signInWithMicrosoft: () => Promise.resolve(),
  signInWithSAML: () => Promise.resolve(),
  updateUser: () => {}
})

export const AuthProvider = (props) => {
  const { children } = props
  const router = useRouter()
  const [state, dispatch] = useReducer(reducer, initialState)
  const location = useLocation()

  const _signOut = useCallback(async () => {
    dispatch({
      payload: {
        signingOut: true
      }
    })
    await signOut(auth)
    dispatch({
      type: ActionType.AUTH_STATE_CHANGED,
      payload: {
        signingOut: false,
        isAuthenticated: false,
        user: null
      }
    })
  }, [])

  const handleAuthStateChanged = useCallback(
    async (user) => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.

        let mappedUser
        try {
          mappedUser = await usersRepo.getUser()
        } catch (error) {
          // If something goes wrong, log out the user
          toast("Safety first! You've been logged out automatically.", {
            icon: <InfoOutlinedIcon color="warning" />
          })
          return await _signOut()
        }

        if (
          mappedUser.status === 'DEMO_SETUP' &&
          !location.pathname.includes('checkout')
        ) {
          // Navigate to demo account page
          router.push(paths.signUpForm, {
            state: { from: localStorageService.getItem('pathname') }
          })
        }

        // Handle resetting the local storage depending on the user's account
        localStorageService.handleAuthStateChange(mappedUser.email)

        dispatch({
          type: ActionType.AUTH_STATE_CHANGED,
          payload: {
            signingOut: false,
            isAuthenticated: true,
            user: {
              uid: mappedUser.uid,
              email: mappedUser.email,
              fullName: mappedUser.fullName,
              firstName: mappedUser.firstName,
              jobTitle: mappedUser.jobTitle,
              manager: mappedUser.manager,
              status: mappedUser.status,
              userRoles: mappedUser.userRoles,
              companyName: mappedUser.companyName,
              companyLogo: mappedUser?.companyLogo || undefined,
              companyColorCode: mappedUser?.companyColorCode,
              upgradeRequested: mappedUser?.upgradeRequested,
              upgradeViewed: mappedUser?.upgradeViewed,
              tutorials: mappedUser.tutorials,
              permissions: mappedUser.permissions,
              features: mappedUser.features
            }
          }
        })

        // Collect user info for Sentry
        configureScope((scope) => {
          scope.setUser({
            id: mappedUser ? mappedUser.uid : 'User id not found',
            username: mappedUser ? mappedUser.fullName : 'User name not found',
            email: mappedUser ? mappedUser.email : 'User email not found',
            roles: mappedUser
              ? mappedUser.userRoles.join(', ')
              : 'User roles not found'
          })
        })
      } else {
        dispatch({
          type: ActionType.AUTH_STATE_CHANGED,
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    },
    [dispatch, _signOut, router, location.pathname]
  )

  useEffect(
    () => onAuthStateChanged(auth, handleAuthStateChanged)(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const _signInWithEmailAndPassword = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password)
  }, [])

  const _createUserWithEmailAndPassword = useCallback(
    async (email, password) => {
      await createUserWithEmailAndPassword(auth, email, password)
    },
    []
  )

  const signInWithGoogle = useCallback(async () => {
    // Use window.location.pathname instead of location.pathname from react-router-dom
    // Because it can't get the last pathname value as we change between /login and /signup
    localStorageService.setItem('pathname', window.location.pathname)
    // Google SSP
    const provider = new GoogleAuthProvider()
    provider.addScope('email')
    provider.setCustomParameters({
      // Force account selection page
      prompt: 'select_account'
    })

    await signInWithPopup(auth, provider)
  }, [])

  const signInWithMicrosoft = useCallback(async () => {
    // Use window.location.pathname instead of location.pathname from react-router-dom
    // Because it can't get the last pathname value as we change between /login and /signup
    localStorageService.setItem('pathname', window.location.pathname)
    // Microsoft SSP
    const provider = new OAuthProvider('microsoft.com')
    provider.setCustomParameters({
      // Force account selection page
      prompt: 'select_account'
    })

    await signInWithPopup(auth, provider)
  }, [])

  const signInWithSAML = useCallback(async (providerId) => {
    // Use window.location.pathname instead of location.pathname from react-router-dom
    // Because it can't get the last pathname value as we change between /login and /signup
    localStorageService.setItem('pathname', window.location.pathname)
    const provider = new SAMLAuthProvider(providerId)
    await signInWithPopup(auth, provider)
  }, [])

  const updateUser = useCallback((user) => {
    dispatch({
      type: ActionType.UPDATE_USER,
      payload: {
        user
      }
    })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
        signInWithEmailAndPassword: _signInWithEmailAndPassword,
        signOut: _signOut,
        signInWithGoogle,
        signInWithMicrosoft,
        signInWithSAML,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const AuthConsumer = AuthContext.Consumer
