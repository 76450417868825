export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  DEMO: 'DEMO',
  DEMO_SETUP: 'DEMO_SETUP'
}

export const COMPANY_STATUS = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  DEMO: 'DEMO'
}
