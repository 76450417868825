import schemas from '/functions/shared/schemas'
import consoleLog from '/src/utils/console-log'
import makeRequest from '/src/data/index'
import goalsModal from '/src/data/modals/goals-modal'

const goalsRepo = {
  getUserGoals: async () => {
    try {
      const result = await makeRequest({ endpoint: 'getUserGoals' })

      consoleLog('result in getUserGoals before parsing and validation', result)

      // Validating the result
      const validatedResult = schemas.getUserGoals.validateSync(result)

      consoleLog('result in getUserGoals after validation', validatedResult)

      // Modal processing
      const modalResult = validatedResult.map(goalsModal.formatGoal)

      consoleLog('result in getUserGoals after modal', modalResult)

      return modalResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  createGoal: async (data) => {
    try {
      const result = await makeRequest({ data, endpoint: 'createGoal' })

      consoleLog('result in createGoal before parsing and validation', result)

      // Validating the result
      const validatedResult = schemas.createGoal.validateSync(result)

      consoleLog('result in createGoal after validation', validatedResult)

      // Modal processing
      const modalResult = goalsModal.formatGoal(validatedResult)

      consoleLog('result in createGoal after modal', modalResult)

      return modalResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  updateGoal: async (data) => {
    try {
      const result = await makeRequest({ data, endpoint: 'updateGoal' })

      consoleLog('result in updateGoal before parsing and validation', result)

      // Validating the result
      const validatedResult = schemas.updateGoal.validateSync(result)

      consoleLog('result in updateGoal after validation', validatedResult)

      // Modal processing
      const modalResult = goalsModal.formatGoal(validatedResult)

      consoleLog('result in updateGoal after modal', modalResult)

      return modalResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  deleteGoal: async (data) => {
    try {
      const result = await makeRequest({ data, endpoint: 'deleteGoal' })

      consoleLog('result in deleteGoal before parsing and validation', result)

      // Validating the result
      const validatedResult = schemas.deleteGoal.validateSync(result)

      consoleLog('result in deleteGoal after validation', validatedResult)

      return validatedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  batchGetGoals: async (data) => {
    try {
      const result = await makeRequest({ data, endpoint: 'batchGetGoals' })

      consoleLog(
        'result in batchGetGoals before parsing and validation',
        result
      )

      // Validating the result
      const validatedResult = schemas.batchGetGoals.validateSync(result)

      consoleLog('result in batchGetGoals after validation', validatedResult)

      // Modal processing
      const modalResult = goalsModal.prepareForBatchGetGoals(validatedResult)

      consoleLog('result in batchGetGoals after modal', modalResult)

      return modalResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  }
}

export default goalsRepo
