import {
  UserGoals,
  GoalForBatchGetGoals,
  CreatedGoal
} from '/src/sections/dashboard/goals/types'
import { formatUnixDate } from '/src/utils/format-unix-date'
import { FirestoreTimestamp } from '/types/common'
import { UserInterface } from '/types/user'

type FormattedGoal = CreatedGoal | GoalForBatchGetGoals

type UnFormattedGoal = FormattedGoal & {
  dueDate: FirestoreTimestamp
  createdDate: FirestoreTimestamp
}

interface GoalWithOverdueAndActiveGoals extends UserInterface {
  activeGoals: FormattedGoal[]
  overdueGoals: FormattedGoal[]
}

class GoalsModal {
  // STATIC VARIABLES //

  static dueSoonThresholdDays = 14

  // UTILITY FUNCTIONS //
  isGoalOverDue(goal: FormattedGoal): boolean {
    if (!goal.dueDate) return false
    const dueDate = new Date(goal.dueDate)
    const today = new Date()
    // Set time to midnight for both dates to compare only the date part
    dueDate.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)
    return dueDate < today
  }
  sortGoals(goals: FormattedGoal[]): FormattedGoal[] {
    return goals.sort((a, b) => {
      // First, compare by priority
      if (a.priority !== b.priority) {
        return a.priority - b.priority
      }
      // If priorities are the same, compare by due date
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
    })
  }
  groupGoalsByDueDate(goals: FormattedGoal[]): {
    active: FormattedGoal[]
    overdue: FormattedGoal[]
  } {
    return {
      active: this.sortGoals(goals.filter((goal) => !this.isGoalOverDue(goal))),
      overdue: this.sortGoals(goals.filter((goal) => this.isGoalOverDue(goal)))
    }
  }

  calculateOverallProgress(goals: FormattedGoal[]): number {
    if (goals.length === 0) return 0

    const totalProgress = goals.reduce(
      (sum, goal) => sum + goal.reportedProgress,
      0
    )
    return Math.round(totalProgress / goals.length)
  }

  countDueSoonGoals(activeGoals: FormattedGoal[]): number {
    const twoWeeksFromNow = new Date(
      Date.now() + GoalsModal.dueSoonThresholdDays * 24 * 60 * 60 * 1000
    )
    return activeGoals.filter((goal) => goal.dueDate <= twoWeeksFromNow).length
  }

  getActiveGoalsOverallStatistics(activeGoals: FormattedGoal[]) {
    return {
      overallProgress: this.calculateOverallProgress(activeGoals),
      dueSoonGoals: this.countDueSoonGoals(activeGoals)
    }
  }
  formatGoal = (goal: UnFormattedGoal): FormattedGoal => {
    return {
      ...goal,
      dueDate: formatUnixDate(goal.dueDate) as Date,
      createdDate: formatUnixDate(goal.createdDate) as Date
    }
  }

  // PRIVATE HELPER FUNCTIONS //
  #groupGoalsByUser = (goals: GoalForBatchGetGoals[]) => {
    const goalsByUser: Record<string, GoalWithOverdueAndActiveGoals> = {}

    goals.forEach((goal) => {
      if (!goalsByUser[goal.userId]) {
        goalsByUser[goal.userId] = {
          ...goal.userInfo,
          activeGoals: [],
          overdueGoals: []
        }
      }

      if (this.isGoalOverDue(goal)) {
        goalsByUser[goal.userId]?.overdueGoals?.push(goal)
      } else {
        goalsByUser[goal.userId]?.activeGoals?.push(goal)
      }
    })

    return Object.values(goalsByUser)
  }
  #postOperationsOnGroupedByUserGoals = (
    groupedGoals: GoalWithOverdueAndActiveGoals[]
  ) => {
    return groupedGoals.map((userGoals) => {
      // Active goals statistics
      const { dueSoonGoals, overallProgress } =
        this.getActiveGoalsOverallStatistics(userGoals.activeGoals)

      return {
        ...userGoals,
        activeGoals: this.sortGoals(userGoals.activeGoals),
        overdueGoals: this.sortGoals(userGoals.overdueGoals),
        activeGoalsOverallProgress: overallProgress,
        dueSoonActiveGoals: dueSoonGoals
      }
    })
  }

  // DATA PREPARATION METHODS //
  prepareForBatchGetGoals(goals: UnFormattedGoal[]) {
    // Format goals
    const formattedGoals = goals.map(this.formatGoal)

    // Group goals by user
    const groupedByUserGoals = this.#groupGoalsByUser(
      formattedGoals as GoalForBatchGetGoals[]
    )

    // Make post operations on grouped goals
    const postOperatedGoals = this.#postOperationsOnGroupedByUserGoals(
      groupedByUserGoals
    ) as UserGoals[]

    return postOperatedGoals
  }
}

const goalsModal = new GoalsModal()

export default goalsModal
