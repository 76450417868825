interface CreateObjectFromArrayParams<T> {
  array: T[]
  fieldToKey: keyof T
}

export const createObjectFromArrayOfObjects = <T extends Record<string, any>>({
  array,
  fieldToKey
}: CreateObjectFromArrayParams<T>): Record<string, T> => {
  if (!Array.isArray(array) || typeof fieldToKey !== 'string') {
    console.warn('createObjectFromArrayOfObjects: Invalid parameters', {
      array,
      fieldToKey
    })
    return {}
  }

  return array.reduce<Record<string, T>>((acc, curr) => {
    const key = String(curr[fieldToKey])
    acc[key] = curr
    return acc
  }, {})
}
