import { Suspense, lazy } from 'react'
import { GuestGuard } from '/src/guards/guest-guard'
import { Layout as AuthLayout } from '/src/layouts/auth/classic-layout'
import { AnimatedLogo } from '/src/components/animated-logo'
import { paths } from '/src/constants/paths'
import LoadingOverlay from '/src/components/loading-overlay'

// Firebase
const FirebaseLoginPage = lazy(() => import('/src/pages/auth/login'))

const AuthWrapper = ({ children }) => (
  <GuestGuard>
    <AuthLayout>
      <Suspense
        fallback={
          <LoadingOverlay mx={10} my={4} height={50}>
            <AnimatedLogo width={60} />
          </LoadingOverlay>
        }
      >
        {children}
      </Suspense>
    </AuthLayout>
  </GuestGuard>
)

export const authRoutes = [
  {
    path: paths.login,
    element: (
      <AuthWrapper>
        <FirebaseLoginPage isLogin={true} />
      </AuthWrapper>
    )
  },
  {
    path: paths.signUp,
    element: (
      <AuthWrapper>
        <FirebaseLoginPage isLogin={false} />
      </AuthWrapper>
    )
  }
]
