import { Box } from '@mui/material'
import { useDialog } from '/src/hooks/use-dialog'
import CustomDialog from '/src/components/custom-dialog'
import Pricing from '/src/components/pricing'
import PricingHeader from '/src/components/pricing/pricing-header'
import { toast } from 'react-toastify'
import { REDIRECTED_TO_CHECKOUT_FROM } from '/functions/shared/constants/payment'
import { useNavigateCheckout } from '/src/hooks/requests/use-navigate-checkout'
import { useUpgradeRequest } from '/src/hooks/requests/use-upgrade-request'
import { useCallback } from 'react'
import StraightOutlinedIcon from '@mui/icons-material/StraightOutlined'
import PrimaryButton from '/src/components/buttons/primary-button'
import { BILLING_PERIOD } from '/functions/shared/constants/payment'
import { UserInterface } from '/types/user'

type BillingPeriod = (typeof BILLING_PERIOD)[keyof typeof BILLING_PERIOD]

const UpgradeToFullVersionButton = ({
  user,
  isSelfServiceOn
}: {
  user: UserInterface
  isSelfServiceOn: boolean
}) => {
  // Dialog for upgrade request
  // Dialog for upgrade request
  const upgradeDialog = useDialog()

  // Check if user has already requested an upgrade or viewed the upgrade page
  // Then render ui accordingly
  const isUpgradeRequested = user?.upgradeRequested ?? false
  const isUpgradeViewed = user?.upgradeViewed ?? false

  // Request handler for upgrade request
  const { mutateAsync: upgradeRequest, isLoading: isLoadingUpgradeRequest } =
    useUpgradeRequest()

  const {
    mutateAsync: createCheckoutSession,
    isLoading: isLoadingCreateCheckoutSession
  } = useNavigateCheckout({ from: REDIRECTED_TO_CHECKOUT_FROM.DEMO, user })

  // Send upgrade request and handle promise states with toast
  const handleUpgrade = useCallback(async () => {
    await toast.promise(upgradeRequest(), {
      pending: 'Sending upgrade request...',
      success: 'Upgrade request sent!',
      error: 'Failed to send upgrade request. Please try again.'
    })
  }, [upgradeRequest])

  // Checkout handler for upgrade request
  const handleCheckout = useCallback(
    async ({
      companySize,
      billingPeriod
    }: {
      companySize: number
      billingPeriod: BillingPeriod
    }) => {
      await toast.promise(
        createCheckoutSession({ companySize, billingPeriod } as any),
        {
          pending: 'Redirecting to checkout...',
          error: {
            render({ data }) {
              console.warn('createCheckoutSession error: ', data)
              return 'Error redirecting the checkout page'
            }
          }
        }
      )
    },
    [createCheckoutSession]
  )

  // Handle upgrade viewed
  const handleUpgradeViewed = useCallback(async () => {
    upgradeDialog.handleOpen()
    if (!isUpgradeViewed) {
      await upgradeRequest({ upgradeViewed: true } as any)
    }
  }, [upgradeRequest, upgradeDialog, isUpgradeViewed])

  // Check if any of the requests are loading
  const isLoading = isLoadingUpgradeRequest || isLoadingCreateCheckoutSession
  return (
    <CustomDialog
      title=""
      maxWidth="lg"
      openButton={
        <PrimaryButton
          size="small"
          onClick={handleUpgradeViewed}
          endIcon={<StraightOutlinedIcon />}
        >
          Explore Full Version
        </PrimaryButton>
      }
      controller={upgradeDialog}
    >
      <Box mb={3}>
        <PricingHeader />
      </Box>
      <Pricing
        isUpgradeRequested={isUpgradeRequested}
        isLoading={isLoading}
        isSelfServiceOn={isSelfServiceOn}
        handleUpgrade={handleUpgrade}
        handleCheckout={handleCheckout}
      />
    </CustomDialog>
  )
}

export default UpgradeToFullVersionButton
