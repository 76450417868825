type CreateObjectFromKeysInput<T extends Record<string, any>> = {
  object: T
  keys: (keyof T)[]
}

export function createObjectFromKeys<T extends Record<string, any>>({
  object,
  keys
}: CreateObjectFromKeysInput<T>): Partial<T> {
  // Check if inputs are valid
  if (typeof object !== 'object' || !Array.isArray(keys)) {
    console.error('Invalid input types')
    return {}
  }

  return keys.reduce<Partial<T>>((filteredObject, key) => {
    if (key in object && object[key] !== undefined) {
      filteredObject[key] = object[key]
    }
    return filteredObject
  }, {})
}
