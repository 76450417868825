// Importing all the schemas
import * as aiSchemas from './ai-schemas.js'
import * as appConfigSchemas from './app-config-schemas.js'
import * as demoSchemas from './demo-schemas.js'
import * as requestSchemas from './request-schemas.js'
import * as employeeSchemas from './employee-schemas.js'
import * as evaluationSchemas from './evaluation-schemas.js'
import * as organizationSchemas from './organization-schemas.js'
import * as resultSchemas from './result-schemas.js'
import * as reviewSchemas from './review-schemas.js'
import * as statSchemas from './stat-schemas.js'
import * as userSchemas from './user-schemas.js'
import * as integrationSchemas from './integration-schemas.js'
import * as paymentSchemas from './payment-schemas.js'
import * as publicSchemas from './public-schemas.js'
import * as goalSchemas from './goal-schemas.js'
import * as dashboardSchemas from './dashboard-schemas.js'

// Exporting all the schemas
const schemas = {
  ...aiSchemas,
  ...appConfigSchemas,
  ...demoSchemas,
  ...requestSchemas,
  ...employeeSchemas,
  ...evaluationSchemas,
  ...organizationSchemas,
  ...resultSchemas,
  ...reviewSchemas,
  ...statSchemas,
  ...userSchemas,
  ...integrationSchemas,
  ...goalSchemas,
  ...paymentSchemas,
  ...publicSchemas,
  ...dashboardSchemas
}

export default schemas
