import ReactDOM from 'react-dom/client'
import { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { App } from '/src/app'
import { CustomRouter } from '/src/components/router/custom-router'
import { history } from '/src/components/router/history'
import ErrorBoundary from '/src/components/sentry-error-boundary'
import ReactGA from 'react-ga4'
import { initRefinerSurvey } from './refiner/config'

// Initialize sentry
import '/src/config/sentry'

initRefinerSurvey()

ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ErrorBoundary>
    <HelmetProvider>
      <CustomRouter history={history}>
        <Suspense>
          <App />
        </Suspense>
      </CustomRouter>
    </HelmetProvider>
  </ErrorBoundary>
)
