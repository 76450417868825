import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { useDialog } from '/src/hooks/use-dialog'
import usersRepo from '/src/data/repos/users-repo'
import consoleLog from '/src/utils/console-log'
import { tutorials } from '/src/constants/tutorials'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import {
  Box,
  Typography,
  List,
  ListItem,
  Stack,
  CircularProgress,
  SvgIcon
} from '@mui/material'
import { useState } from 'react'
import { TUTORIAL_IDS } from '/src/constants/tutorials'
import Center from '/src/components/center'
import TextButton from '/src/components/buttons/text-button'
import PrimaryButton from '/src/components/buttons/primary-button'
import { useUser } from '/src/hooks/use-user'

export const useTutorial = ({
  tutorialId = '',
  pages = [],
  page = 0,
  handleComplete
}) => {
  const [tutorial, setTutorial] = useState({
    page
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Video loading states
  const [isLoading, setIsLoading] = useState(true)

  const handleNext = async () => {
    setIsLoading(true)

    // If we are on the last page, then we want to complete the tutorial
    if (tutorial.page === pages.length - 1) {
      // Update tutorial status in the backend for the given tutorial name
      setIsSubmitting(true)
      await handleComplete(tutorialId)
      setIsSubmitting(false)
      return
    }

    return setTutorial((prevState) => {
      return {
        ...prevState,
        page: prevState.page + 1
      }
    })
  }

  const handlePrev = () => {
    setIsLoading(true)

    // if we are on the first page, then we want to do nothing
    if (tutorial.page === 0) return

    setTutorial((prevState) => {
      return {
        ...prevState,
        page: prevState.page - 1
      }
    })
  }

  return {
    tutorial,
    handleNext,
    handlePrev,
    isSubmitting,
    isLoading,
    setIsLoading
  }
}

export const TutorialContentDisplay = ({
  pages,
  tutorialId,
  handleComplete,
  isRequired
}) => {
  const {
    handleNext,
    handlePrev,
    tutorial,
    isSubmitting,
    isLoading,
    setIsLoading
  } = useTutorial({
    tutorialId,
    page: 0,
    pages: pages,
    handleComplete
  })

  const tutorialDetails = pages[tutorial.page]
  const index = tutorial.page
  const length = pages.length

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 1,
          width: '100%'
        }}
      >
        <TextButton
          size="large"
          endIcon={
            <SvgIcon>
              <SkipNextIcon />
            </SvgIcon>
          }
          style={{ visibility: isRequired ? 'hidden' : 'visible' }}
          onClick={() => handleComplete()}
        >
          Exit
        </TextButton>
      </Box>
      <Stack
        sx={{
          flexDirection: {
            md: 'column',
            lg: 'row'
          },
          flexGrow: 1,
          overflow: 'auto'
        }}
      >
        {tutorialDetails?.videoEmbed ? (
          <Box sx={{ px: 5, width: '100%', height: '100%', margin: '0 auto' }}>
            <iframe
              title="Tutorial Video"
              src={tutorialDetails.videoEmbed}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                borderRadius: '8px'
              }}
            ></iframe>
          </Box>
        ) : (
          <>
            {/* Texts */}
            <Stack
              sx={{
                p: 10,
                height: '100%',
                width: '100%',
                flexGrow: 1,
                alignItems: 'start',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h2">
                {tutorialDetails?.title || ''}
              </Typography>
              <Typography
                sx={{
                  marginTop: 3
                }}
                variant="body1"
              >
                {tutorialDetails?.description || ''}
              </Typography>
              <List>
                {tutorialDetails?.bulletPoints.map((bulletPoint, i) => (
                  <ListItem
                    key={i}
                    sx={{
                      padding: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        marginTop: 3
                      }}
                      variant="h6"
                    >
                      {bulletPoint?.title || ''}
                    </Typography>
                    <Typography
                      sx={{
                        marginTop: 1
                      }}
                    >
                      {bulletPoint?.description || ''}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Stack>
            {/* Visual */}
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%'
              }}
            >
              <Stack
                sx={{
                  boxShadow: 24,
                  borderRadius: 10,
                  width: '90%',
                  minHeight: 300
                }}
              >
                <Center
                  sx={{
                    position: 'relative'
                  }}
                >
                  {isLoading ? (
                    <Center
                      sx={{
                        position: 'absolute',
                        zIndex: 1000,
                        height: '100%',
                        width: '100%',
                        // Add blur effect
                        backdropFilter: 'blur(5px)'
                      }}
                    >
                      <CircularProgress />
                    </Center>
                  ) : null}
                  {tutorialDetails?.videoUrl ? (
                    <video
                      width="100%"
                      height="100%"
                      autoPlay
                      loop
                      volume={1}
                      muted={true}
                      src={tutorialDetails.videoUrl}
                      preload="auto"
                      onCanPlay={() => setIsLoading(false)}
                    >
                      <source src={tutorialDetails.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={tutorialDetails?.imageSrc || ''}
                      alt={tutorialDetails?.imageAlt || ''}
                      style={{
                        width: '100%',
                        height: '100%'
                      }}
                      onLoad={() => setIsLoading(false)}
                    />
                  )}
                </Center>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
      {/* Buttons: Previous and Next */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        m={3}
      >
        <PrimaryButton
          color="primary"
          onClick={handlePrev}
          style={{ visibility: index === 0 ? 'hidden' : 'visible' }}
        >
          Previous
        </PrimaryButton>

        <Typography sx={{ fontSize: 16 }}>{`${
          index + 1
        } of ${length}`}</Typography>

        <PrimaryButton
          color="primary"
          onClick={handleNext}
          disabled={isSubmitting}
        >
          {index + 1 === length
            ? tutorialId === TUTORIAL_IDS.ADMIN_INTRO_TUTORIAL && isRequired
              ? 'Start Exploring'
              : 'Complete'
            : 'Next'}
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}

const TutorialModal = ({
  tutorialId,
  user = null,
  isRequired = false,
  onClose = () => null
}) => {
  const tutorialPages = tutorials[tutorialId]['contents']
  const { open, handleClose, handleOpen } = useDialog()
  const { updateUser } = useUser()

  const handleComplete = React.useCallback(
    async (tutorialId) => {
      if (!isRequired) {
        onClose()
        return handleClose()
      }
      // Update tutorial status in the backend for the given tutorial name
      try {
        const result = await usersRepo.updateTutorials({
          name: tutorialId,
          completed: true
        })
        updateUser({
          ...user,
          tutorials: result
        })

        consoleLog('result in updateTutorials', result)
        return handleClose()
      } catch (error) {
        console.warn(error)
      }
    },
    [handleClose, updateUser, user, onClose, isRequired]
  )

  React.useEffect(() => {
    handleOpen(tutorialId)
    return () => {}
  }, [tutorialId, handleOpen])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            // Center modal in the middle of the screen
            'position': 'absolute',
            'top': '50%',
            'left': '50%',
            'transform': 'translate(-50%, -50%)',
            // Styling
            'bgcolor': 'background.paper',
            '&:focus': {
              outline: 'none'
            },
            // Spacing
            'width': '95%',
            'height': '95%',
            'borderRadius': 2,
            'maxWidth': 2000,
            'maxHeight': 1200
          }}
        >
          {
            <TutorialContentDisplay
              {...{
                tutorialId,
                pages: tutorialPages,
                handleComplete,
                isRequired
              }}
            />
          }
        </Box>
      </Fade>
    </Modal>
  )
}

export default TutorialModal
