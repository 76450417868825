import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined'
import {
  Box,
  ButtonBase,
  SvgIcon,
  Tooltip,
  Theme,
  BoxProps
} from '@mui/material'
import { usePathname } from '/src/hooks/use-pathname'
import { useTheme } from '@mui/system'

interface FAQButtonProps extends BoxProps {
  sectionParamType?: string
  settings: {
    handleSettingsPreviewUpdate: (arg: { faqDrawerOpen: string }) => void
  }
}

export const FAQButton: React.FC<FAQButtonProps> = (props) => {
  const { sectionParamType, settings, ...other } = props
  const theme = useTheme<Theme>()
  const pathName = usePathname()
  const sectionPathType = pathName === '/' ? 'index' : pathName.split('/').pop() // Evaluate for index page

  const handleDrawerOpen = () =>
    settings.handleSettingsPreviewUpdate({
      faqDrawerOpen: sectionParamType ? sectionParamType : sectionPathType || ''
    })

  return (
    <Tooltip title="Help">
      <Box {...other}>
        <ButtonBase
          data-gaid="help-button"
          sx={{
            backgroundColor: 'primary.main',
            borderRadius: '50%',
            color:
              theme.palette.mode === 'light'
                ? theme.palette.primary.contrastText
                : 'primary.contrastText',
            p: '4px'
          }}
          onClick={handleDrawerOpen}
        >
          <SvgIcon>
            <QuestionMarkOutlinedIcon />
          </SvgIcon>
        </ButtonBase>
      </Box>
    </Tooltip>
  )
}
