import { array, object, string, boolean } from 'yup'
import { ROLES } from '../constants/roles.js'
import { userSchema } from './common/user-object.js'
import { featuresMap } from '../constants/features.js'

const tutorialsSchema = array().of(
  object().shape({
    name: string().required('Name field is required.'),
    completed: boolean().required('Completed field is required.')
  })
)

export const getUser = object()
  .shape({
    email: string().required('Email field is required.'),
    fullName: string().required('Full name field is required.'),
    uid: string().required('UID field is required.'),
    userRoles: array()
      .of(
        string()
          .oneOf([ROLES.ADMIN, ROLES.MANAGER, ROLES.EMPLOYEE])
          .required('User role must be a valid user role.')
      )
      .required('User roles field is required.'),
    status: string().required('Status field is required.'),
    // Conditional fields that are related to the status
    firstName: string().when('status', {
      is: (_) => 'DEMO_SETUP',
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required('firstName field is required.')
    }),
    companyName: string().when('status', {
      is: (_) => 'DEMO_SETUP',
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required('Company name field is required.')
    }),
    companyLogo: string().when('status', {
      is: (_) => 'DEMO_SETUP',
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.defined('Company logo field is required.')
    }),
    companyColorCode: string().when('status', {
      is: (_) => 'DEMO_SETUP',
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.required('Company color code field is required.')
    }),
    manager: string().when('status', {
      is: (_) => 'DEMO_SETUP',
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.defined('Manager field is required.')
    }),
    upgradeRequested: boolean().notRequired(),
    upgradeViewed: boolean().notRequired(),
    tutorials: tutorialsSchema.notRequired(),
    permissions: object().notRequired(),
    // Optional fields
    jobTitle: string().notRequired(),
    features: object()
      .shape(
        Object.keys(featuresMap).reduce((acc, key) => {
          acc[featuresMap[key]] = boolean().optional()
          return acc
        }, {})
      )
      .notRequired()
  })
  .noUnknown() // to prevent extra fields from being added to the object and activating striptUnknown

export const updateTutorials = tutorialsSchema.required(
  'Tutorials are required.'
)

export const batchGetUsers = array().of(userSchema).ensure()

export const setUserPermissions = userSchema

export const deleteIncorrectDemoUser = object()
  .shape({
    message: string().required('Message is required.')
  })
  .noUnknown()
