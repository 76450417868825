import { TextField, TextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const RedditTextField = styled((props: TextFieldProps) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    'overflow': 'hidden',
    'borderRadius': 4,
    'backgroundColor': 'transparent',
    'border': '1px solid',
    'borderColor': '#E0E3E7',
    'transition': theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main
    },
    '&::after': {
      display: 'none'
    },
    '&::before': {
      display: 'none'
    }
  }
}))

export default RedditTextField
