import React, { useCallback, useState } from 'react'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CelebrationIcon from '@mui/icons-material/Celebration'
import {
  alpha,
  Box,
  Button,
  Divider,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import {
  BI_ANNUALLY_DISCOUNT,
  BIANNUAL_PRICE,
  CURRENCY_SYMBOL,
  MONTHLY_PRICE,
  pricing as pricingOptions
} from './constants'
import { Grid, NumberOfEmployees, PriceTable } from './components'
import { BILLING_PERIOD } from '/functions/shared/constants/payment'

type BillingPeriod = (typeof BILLING_PERIOD)[keyof typeof BILLING_PERIOD]

interface PricingPropTypes {
  isUpgradeRequested: boolean
  isLoading: boolean
  isSelfServiceOn: boolean
  handleUpgrade: () => void
  handleCheckout: ({
    companySize,
    billingPeriod
  }: {
    companySize: number
    billingPeriod: BillingPeriod
  }) => void
}

const Pricing = ({
  isUpgradeRequested,
  isLoading,
  isSelfServiceOn,
  handleUpgrade = () => {},
  handleCheckout = () => {}
}: PricingPropTypes) => {
  // Number of employees state and handler
  const [numberOfEmployees, setNumberOfEmployees] = useState(50)
  const handleNumberOfEmployees = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNumberOfEmployees(Math.min(100, Number(event.target.value)))
    },
    []
  )

  // Billing period, price per month state and handler
  const [billingPeriod, setBillingPeriod] = useState(BILLING_PERIOD.MONTHLY)
  const [pricePerMonth, setPricePerMonth] = useState(MONTHLY_PRICE)
  const handleBillingCycle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const newBillingPeriod: string = event.currentTarget.name
      setBillingPeriod(newBillingPeriod)
      setPricePerMonth(
        Number(
          newBillingPeriod === BILLING_PERIOD.MONTHLY
            ? MONTHLY_PRICE
            : BIANNUAL_PRICE
        )
      )
    },
    []
  )

  return (
    <Box>
      <Stack direction="row" justifyContent="center" alignItems="center" mb={2}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
            borderRadius: 4,
            width: 'fit-content',
            padding: 0.4
          }}
        >
          <Button
            size="small"
            variant={
              billingPeriod === BILLING_PERIOD.MONTHLY ? 'contained' : 'text'
            }
            onClick={handleBillingCycle}
            name={BILLING_PERIOD.MONTHLY}
            sx={{
              borderRadius: 4,
              px: 8,
              textTransform: 'none'
            }}
          >
            Monthly
          </Button>
          <Button
            size="small"
            variant={
              billingPeriod === BILLING_PERIOD.BI_ANNUALLY
                ? 'contained'
                : 'text'
            }
            onClick={handleBillingCycle}
            name={BILLING_PERIOD.BI_ANNUALLY}
            sx={{
              borderRadius: 4,
              px: 8,
              textTransform: 'none'
            }}
          >
            Bi-Annually (save {BI_ANNUALLY_DISCOUNT}%)
          </Button>
        </Stack>
      </Stack>
      <Grid>
        {pricingOptions.map((priceTable, i) => (
          <PriceTable key={i}>
            <div>
              <Stack
                sx={{
                  justifyContent: 'space-between',
                  flexDirection: 'row'
                }}
              >
                <Typography variant="h5">{priceTable.title}</Typography>
                <Stack alignItems="center">
                  <Typography variant="h5">{priceTable.range}</Typography>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontWeight: 500
                    }}
                  >
                    employees
                  </Typography>
                </Stack>
              </Stack>
              <Divider
                orientation="horizontal"
                sx={{
                  borderColor: (theme) => theme.palette.primary.main,
                  marginY: 1
                }}
              />
            </div>

            {priceTable.billing ? (
              <Stack
                sx={{
                  borderRadius: '5px'
                }}
              >
                <Stack
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row'
                  }}
                >
                  <Stack>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        mt: 1
                      }}
                    >
                      <Typography variant="h4">
                        {CURRENCY_SYMBOL + pricePerMonth}
                      </Typography>
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                          fontSize: 12,
                          fontWeight: 500,
                          marginLeft: 1,
                          marginTop: 2.5
                        }}
                      >
                        {` /employee/month, billed ${
                          billingPeriod === BILLING_PERIOD.MONTHLY
                            ? 'monthly'
                            : 'bi-annually'
                        }`}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack mt="1rem">
                  <Stack mt="0.3rem" flexDirection="row" alignItems="center">
                    <NumberOfEmployees
                      numberOfEmployees={numberOfEmployees}
                      handleNumberOfEmployees={handleNumberOfEmployees}
                      pricePerMonth={pricePerMonth}
                    />
                  </Stack>
                </Stack>
              </Stack>
            ) : null}
            <Stack>
              {priceTable.desc ? (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    fontSize: 14,
                    fontWeight: '500',
                    textAlign: 'left',
                    my: 1
                  }}
                >
                  {priceTable.desc}
                </Typography>
              ) : null}
              <Stack mt={2} spacing={1.5}>
                {priceTable.features.map((feature, index) => (
                  <Stack
                    key={index}
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      flexDirection: 'row'
                    }}
                  >
                    <SvgIcon
                      component={CheckCircleRoundedIcon}
                      fontSize="small"
                      sx={{
                        color: (theme) => theme.palette.primary.main
                      }}
                    >
                      <CheckCircleRoundedIcon />
                    </SvgIcon>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: 12,
                        fontWeight: '500',
                        marginLeft: 1,
                        textAlign: 'left'
                      }}
                    >
                      {feature}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
            <Box
              sx={{
                marginTop: 'auto'
              }}
            >
              <Button
                variant="contained"
                sx={{
                  ...(priceTable.button.isSecondary && {
                    'backgroundColor': (theme) =>
                      alpha(theme.palette.primary.main, 0.2),
                    'color': (theme) => theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: (theme) =>
                        alpha(theme.palette.primary.main, 0.3)
                    }
                  }),
                  borderRadius: 4,
                  marginTop: 5,
                  width: '100%'
                }}
                onClick={
                  priceTable.button.upgradeRequest
                    ? handleUpgrade
                    : () =>
                        handleCheckout({
                          companySize: numberOfEmployees,
                          billingPeriod: billingPeriod
                        })
                }
                disabled={
                  isLoading ||
                  (isUpgradeRequested && priceTable.title === 'Enterprise') ||
                  (priceTable.title === 'Starter' && !isSelfServiceOn)
                }
                endIcon={
                  isUpgradeRequested && priceTable.title === 'Enterprise' ? (
                    <CelebrationIcon />
                  ) : null
                }
              >
                {priceTable.button.label(
                  (priceTable.title === 'Enterprise' && isUpgradeRequested) ||
                    (priceTable.title === 'Starter' && isSelfServiceOn)
                )}
              </Button>
            </Box>
          </PriceTable>
        ))}
      </Grid>
    </Box>
  )
}

export default Pricing
