import dayjs from 'dayjs'
import { EVALUATION_STATES } from '/src/constants/statuses'
class EvaluationsModal {
  #sortEvaluations = (evaluations) => {
    // sort by status
    const sortOrder = {
      [EVALUATION_STATES.TEMPLATE]: 1,
      [EVALUATION_STATES.ONGOING]: 2,
      [EVALUATION_STATES.AWAITING_RESULTS]: 3,
      [EVALUATION_STATES.COMPLETED]: 4
    }

    evaluations.sort((a, b) => {
      try {
        if (a.status === b.status) {
          // Create dayjs objects from seconds

          let d1
          let d2

          if (a.expectedEndDate) {
            d1 = dayjs.unix(a.expectedEndDate._seconds)
            d2 = dayjs.unix(b.expectedEndDate._seconds)
          } else if (a.endDate) {
            d1 = dayjs.unix(a.endDate._seconds)
            d2 = dayjs.unix(b.endDate._seconds)
          }

          // Compare the dates
          if (d1.isBefore(d2)) {
            return 1
          } else if (d1.isAfter(d2)) {
            return -1
          } else {
            return 0
          }
        }

        return sortOrder[a.status] - sortOrder[b.status]
      } catch (error) {
        console.error(error)
        return 0
      }
    })

    return evaluations
  }

  prepareForTable = (evaluations) => {
    // Sort evaluations by status and date
    const sortedEvaluations = this.#sortEvaluations(evaluations)

    return sortedEvaluations
  }
}

const evaluationsModal = new EvaluationsModal()

export default evaluationsModal
