import dayjs from 'dayjs'
import { FirestoreDate } from '/types/firestore'

export const convertFirestoreDateToTenure = (
  firestoreDate: FirestoreDate
): number => {
  const { _seconds, _nanoseconds } = firestoreDate

  // Convert Firestore date to dayjs object
  const startDate: dayjs.Dayjs = dayjs
    .unix(_seconds)
    .add(_nanoseconds / 1e9, 'second')

  // Calculate the difference in months
  const currentDate: dayjs.Dayjs = dayjs()
  const monthsDifference: number = currentDate.diff(startDate, 'month', true)

  // Calculate the tenure in years
  const tenureInYears: string = (monthsDifference / 12).toFixed(1)

  // Return tenure in the format "x.y years"
  return Number(tenureInYears)
}
